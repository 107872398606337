import React from 'react'

import Layout from '../components/layout'
import LiveChat from '../components/livechat';

const ContactPage = () => ( 
  <Layout articlelevel={100}>
  <LiveChat/>
  <>
    <div className="contentWrapper flex">
    <div className="content content--inverted">
      <p> 
        <h3 className="roboto"> Redaktion:</h3>
      <h4>Ansvarig utgivare</h4>
            Lilian Almroth<br/>
            010-184 43 79<br/>
            lilian.almroth@lrfmedia.se
      </p>
      <p>
        <h4>Reporter</h4>
        Malin Eborn<br/>
        010-184 44 01<br/>
        malin.eborn@lrfmedia.se<br/><br/>
      </p>
      <p>
        <h3 className="roboto"> Ansvarig säljare:</h3>
        Linda Palmqvist<br/>
        +46 (0)72-142 08 81<br/>
        linda.palmqvist@lrfmedia.se  
      </p>
        </div>
    </div>
    </>
  </Layout>
)

export default ContactPage