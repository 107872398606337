import { useEffect } from 'react'

const scriptText = `(function (g, e, n, es, ys) {
  g['_genesysJs'] = e;
  g[e] = g[e] || function () {
    (g[e].q = g[e].q || []).push(arguments)
  };
  g[e].t = 1 * new Date();
  g[e].c = es;
  ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
})(window, 'Genesys', 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js', {
  environment: 'euc1',
  deploymentId: '322293c4-4d4c-4081-9989-8fdbc767a34b'
});`

export default function LiveChat() {
  const showChat = () => {
    setTimeout(() => {
      if (!window.Genesys) {
        showChat()
      } else {
        window.Genesys('subscribe', 'Launcher.ready', function () {
          window.Genesys('command', 'launcher.show')
        })
      }
    }, 1000)
  }

  useEffect(() => {
    const livechatScript = document.createElement('script')
    // livechat code from platform
    livechatScript.text = scriptText

    const s = document.getElementsByTagName('script')[0]

    if (s && s.parentNode) {
      s.parentNode.insertBefore(livechatScript, s)
    }
    showChat()

    return () => {
      livechatScript.parentNode?.removeChild(livechatScript)
    }
  }, [])

  return null
}
